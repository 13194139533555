<template>
  <div class="app-card">
    <a-tabs type="card">
      <a-tab-pane key="announcement" tab="政策通告">
        <div>
          <div v-if="!isAnnouncementEdit" v-html="announcement"></div>
          <div v-else>
            <div style="height:500px;">
                <quill-editor
                  style="height:440px;"
                  v-model="announcement"
                  :options="{placeholder: '请填写内容', ...editerOptions}"
                />
              </div>
          </div>
        </div>
        <div style="text-align: center;padding-top:60rpx;">
          <div v-if="!isAnnouncementEdit">
            <a-button type="primary" @click="isAnnouncementEdit = true">
              {{announcement ? '编辑' : '编写'}}
            </a-button>
          </div>
          <div v-else>
            <a-button type="primary" @click="announcementEdit">
              确定
            </a-button>
          </div>
        </div>
        
      </a-tab-pane>
    </a-tabs>
    
    
    <a-modal width="80%" :title="createAndEditModalTitle" v-model="createAndEditShow" @ok="createAndEditModalOk" >
      <a-form-model ref="createAndEditForm" :model="createAndEditForm" :rules="createAndEditFormRules" v-bind="formLayout">
        <a-form-model-item label='产品分类' prop="type">
          <a-select v-model="createAndEditForm.type" placeholder="请选择">
            <a-select-option v-for="it in productTypeList" :key="it.key">{{it.value}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='标题' prop="title">
          <a-input v-model="createAndEditForm.title" />
        </a-form-model-item>
        <a-divider orientation="left">内容</a-divider>
            <div style="height:500px;">
              <quill-editor
                style="height:440px;"
                v-model="createAndEditForm.content"
                :options="{placeholder: '请填写内容', ...editerOptions}"
              />
            </div>
      </a-form-model>
    </a-modal>

    <a-drawer
      width="640"
      :title="wikiDetail.title"
      placement="right"
      :closable="false"
      :visible="wikiDetailShow"
      @close="wikiDetailShow = false"
    >
      <div v-html="wikiDetail.content"></div>
    </a-drawer>

    <a-modal
      :width="200"
      :visible="modalLoading"
      :closable="false"
      :maskClosable="false"
      :footer="null"
    >
      <a-row>
        <a-col style="text-align: center;">
          <a-icon type="loading" :style="{ fontSize: '36px', color: '#1890ff' }" />
        </a-col>
        <a-col style="text-align: center;color:#1890ff;padding-top:10px;">
          {{modalLoadingMessage}}
        </a-col>
      </a-row>
      
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import { Product, Announcement, ProductWiki } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'

const INIT_SEARCH_FORM = '{"type":"","title":""}'

const columns = [
  {
    title: '产品类型',
    dataIndex: 'type',
    width: 150,
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '标题',
    dataIndex: 'title',
    width: 150,
  },
  {
    title: '审核时间',
    width: 150,
    dataIndex: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 130,
    scopedSlots: { customRender: 'action' },
  }
]

export default {
  name: 'ProductCarInsuranceRate',
  mixins: [aliveVM, mixinTableList],
  async mounted () {
    const baseColumns = []
    this.$set(this, 'columns', JSON.parse(JSON.stringify(columns)))
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    await this.getAnnouncementDetail()
    await this.getProductTypeList()
    this.fetch()
  },
  data () {
    return {
      modalLoading: false,
      modalLoadingMessage: '正在处理数据...',
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      moment,
      tableListApi: `${ProductWiki}/TableList`,
      searchParams: {},
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      columns: [],
      baseColumns: [],
      // other
      announcement: '',
      isAnnouncementEdit: false,
      formLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      },
      editerOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            ["blockquote", "code-block"], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], //列表
            [{ script: "sub" }, { script: "super" }], // 上下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ font: [] }], //字体
            [{ align: [] }], //对齐方式
            ["link"],
            ["clean"], //清除字体样式
            ["image"], //上传图片、上传视频
          ],
        },
        theme: "snow",
      },
      productTypeList: [],
      productTypeDic: {},
      createAndEditModalTitle: '添加Wiki',
      createAndEditShow: false,
      createAndEditForm: {
        type: '',
        title: '',
        content: ''
      },
      createAndEditFormRules: {
        type: [{ required: true, message: "请选择", trigger: "blur" }],
        title: [{ required: true, message: "请填写", trigger: "blur" }]
      },
      wikiDetail: {
        type: '',
        title: '',
        content: ''
      },
      wikiDetailShow: false,
      nowFormType: '',
      nowEditUuid: '',
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', {})
      
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', {})
      
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      let searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (k === 'startAt' || k === 'endAt') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      if (licia.isEmpty(searchParams)) {
        searchParams = {}
      }
      
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    // other
    async getAnnouncementDetail() {
      const apiRes = await this.$axios.get(`${Announcement}/GetDetail?type=InsuranceCarRate`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        this.sending = false;
        return null;
      }
      this.announcement = apiRes.data.content;
    },
    async announcementEdit() {
      if(!this.announcement) {
        this.$message.error("请填写内容");
        return;
      }
      const apiRes = await this.$axios
          .post(`${Announcement}/EditInsuranceCarRateAnnouncement`, {
            content: this.announcement
          })
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });
      if (!apiRes.status) {
        this.announcement = "";
            this.$message.error(apiRes.message);
            this.sending = false;
            return;
          }
      this.isAnnouncementEdit = false
    },
    async getProductTypeList() {
      const res = await this.$axios.get(`${Product}/GetProductTypeList`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!res.status) {
        this.$message.error(res.message)
        return
      }
      this.productTypeList = res.data
      const productTypeDic = {}
      for (const it of res.data) {
        productTypeDic[it.key] = it.value
      }
      this.productTypeDic = productTypeDic
    },
    async addProductWikiClick() {
      this.nowFormType = 'create'
      this.createAndEditModalTitle = '添加Wiki'
      this.createAndEditShow = true
    },
    createAndEditModalOk() {
      this.$refs.createAndEditForm.validate(async (valid) => {
        if (!valid) {
          this.$message.error("请检查填写内容");
          return;
        }
        if (!this.createAndEditForm.content) {
          this.$message.error("请检查填写内容");
          return;
        }
        if (this.nowFormType === 'create') {
          const apiRes = await this.$axios
          .post(`${ProductWiki}/Create`, this.createAndEditForm)
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });
          if (!apiRes.status) {
            this.$message.error(apiRes.message);
            this.sending = false;
            return;
          }
        } else {
          const apiRes = await this.$axios
          .post(`${ProductWiki}/Edit?uuid=${this.nowEditUuid}`, this.createAndEditForm)
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });
          if (!apiRes.status) {
            this.$message.error(apiRes.message);
            this.sending = false;
            return;
          }
        }
        
        this.$message.success(this.nowFormType === 'create' ? '添加成功' : '编辑成功')
        this.createAndEditShow = false
        this.fetch()
      })
    },
    async getDetail (uuid) {
      const apiRes = await this.$axios.delete(`${ProductWiki}/Detail?uuid=${uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        this.sending = false;
        return null;
      }
      return apiRes.data;
    },
    async toEdit (data) {
      this.nowFormType = 'edit'
      this.createAndEditModalTitle = '编辑Wiki'
      const detail = await this.getDetail(data.uuid)
      if (detail === null) return
      this.nowEditUuid = data.uuid
      this.createAndEditForm.type = detail.type
      this.createAndEditForm.title = detail.title
      this.createAndEditForm.content = detail.content
      this.createAndEditShow = true
    },
    async toDelete (data) {
      this.loading = true
      const res = await this.$axios.delete(`${Product}/Delete?uuid=${data.uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      this.loading = false
      if (!res.status) {
        this.$message.error(res.message)
        return
      }
      this.fetch()
    },
    // 详情
    async toDetail (data) {
      const detail = await this.getDetail(data.uuid)
      if (detail === null) return
      this.$set(this, 'wikiDetail', detail)
      this.wikiDetailShow = true
    },
  }
}
</script>

<style>

</style>